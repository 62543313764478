import {
  AppBar,
  Toolbar,
  makeStyles,
  IconButton,
  Drawer,
  Link,
  MenuItem,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Link as RouterLink, NavLink, useHistory } from 'react-router-dom'
import CostumButton from '../Components/Button'
import { colors } from '../Themes'
import Text from '../Components/Text'
import { Search } from '../Themes/Images'
import useScrollDirection from '../Utils/useScroll'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

const desktopHeaderData = [
  {
    key: 1,
    label: 'Home',
    href: '/',
  },
  {
    key: 'about',
    label: 'What We Do',
    href: '/about',
  },
  {
    key: 5,
    label: 'Plans & Pricing',
    href: '/pricing',
  },
  {
    key: 'resources',
    label: 'Resources',
    // href: '/faq',
    isDropDown: true,
    dropDownElements: [
      {
        key: 6,
        label: 'Help & Support',
        href: 'https://dataplayer.zohodesk.eu/portal/en-gb/home',
        img: 'https://dyl2njn37ytxk.cloudfront.net/website-sep9/resources2/book.svg',
        title: 'Help & Support',
        description: 'Learn how to sign up, install and use our products.',
        newWindow: true
      },
      {
        key: 7,
        label: 'Insights & Updates',
        href: 'https://blog.dataplayer.io/',
        img: 'https://dyl2njn37ytxk.cloudfront.net/website-sep9/resources2/rename.svg',
        title: 'Insights & Updates',
        description: 'Learn how to sign up, install and use our products.',
        newWindow: true
      },
      {
        key: 10,
        label: 'Case Studies',
        href: '/testimonials',
        img: 'https://dyl2njn37ytxk.cloudfront.net/website-sep9/resources2/ppl.svg',
        title: 'Case Studies',
        description: 'Learn how to sign up, install and use our products.',
      },
      {
        key: 11,
        label: 'Our Partners',
        href: '/partners',
        img: 'https://dyl2njn37ytxk.cloudfront.net/website-sep9/resources2/hand.svg',
        title: 'Our Partners',
        description: 'Learn how to sign up, install and use our products.',
      },
      {
        key: 12,
        label: 'Data Security',
        href: '/security',
        img: 'https://dyl2njn37ytxk.cloudfront.net/website-sep9/resources2/sec.svg',
        title: 'Data Security',
        description: 'Learn how to sign up, install and use our products.',
      },
      {
        key: 13,
        label: 'Common Questions',
        href: '/faq',
        img: 'https://dyl2njn37ytxk.cloudfront.net/website-sep9/resources2/comment.svg',
        title: 'Common Questions',
        description: 'Learn how to sign up, install and use our products.',
      },
    ]
  },
  {
    key: 9,
    label: 'Get in Touch',
    href: '/contactus',
  },
]

const  headersData = [
  {
    key: 1,
    label: 'Home',
    href: '/',
  },
  {
    key: 'about',
    label: 'What We Do',
    href: '/about',
  },
  {
    key: 'pricing',
    label: 'Plans & Pricing',
    href: '/pricing',
  },
  {
    key: 'resources',
    label: 'Resources',
    // href: '/faq',
    isDropDown: true,
    dropDownElements: [
      {
        key: 6,
        label: 'Help & Support',
        href: 'https://dataplayer.zohodesk.eu/portal/en-gb/home',
        img: 'https://dyl2njn37ytxk.cloudfront.net/website-sep9/resources/Group%201%20(2).svg',
        title: 'Help & Support',
        description: 'Learn how to sign up, install and use our products.',
        newWindow: true
      },
      {
        key: 7,
        label: 'Case Studies',
        href: '/testimonials',
        img: 'https://dyl2njn37ytxk.cloudfront.net/website-sep9/resources/Group%203.svg',
        title: 'Case Studies',
        description: 'Learn how to sign up, install and use our products.',
      },
      {
        key: 10,
        label: 'Insights & Updates',
        href: 'https://blog.dataplayer.io',
        newWindow: true,
        img: 'https://dyl2njn37ytxk.cloudfront.net/website-sep9/resources/Group%202.svg',
        title: 'Insights & Updates',
        description: 'Learn how to sign up, install and use our products.',
      },
      {
        key: 11,
        label: 'Our Partners',
        href: '/partners',
        img: 'https://dyl2njn37ytxk.cloudfront.net/website-sep9/resources/Group%204.svg',
        title: 'Our Partners',
        description: 'Learn how to sign up, install and use our products.',
      },
      {
        key: 12,
        label: 'Data Security',
        href: '/security',
        img: 'https://dyl2njn37ytxk.cloudfront.net/website-sep9/resources/Group%205.svg',
        title: 'Data Security',
        description: 'Learn how to sign up, install and use our products.',
      },
      {
        key: 13,
        label: 'Common Questions',
        href: '/faq',
        img: 'https://dyl2njn37ytxk.cloudfront.net/website-sep9/resources/Group%206.svg',
        title: 'Common Questions',
        description: 'Learn how to sign up, install and use our products.',
      },
    ]
  },
  {
    key: 8,
    label: 'Get in Touch',
    href: '/contactus',
  },
]


const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: '#ffff',
    // paddingInline: '5%',
    // height: 88, 
    display: 'flex',
    justifyContent: 'center',
    boxShadow: '0px 2px 4px -1px rgb(206 206 206 / 90%)',
    transition: 'all 0.5s',
    [theme.breakpoints.down('md')]: {
      // paddingInline: '20px',
    },
    [theme.breakpoints.up(1920)]: {
      // paddingInline: '300px',
    },
  },
  hidden: {
    visibility: 'hidden',
    transition: 'all 0.5s',
    transform: 'translateY(-100%)',
    paddingTop: 110,
  },
  logo: {
    height: 50,
    objectFit: 'contain',
    imageRendering: '-webkit-optimize-contrast',
    background: colors.white,
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      paddingInline: 24
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      maxWidth: '100%',
      paddingInline: 24
     
    },
  },
  mobileLinks: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '91%'
  },
  logoM: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      maxWidth: '200px',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '160px',
    },
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    paddingInline: 70,
    [theme.breakpoints.up(1920)]: {
      paddingInline: 200, 
    },
    [theme.breakpoints.between(960, 1100)]: {
      paddingInline: 35, 
    },
  },
  drawerContainer: {
    padding: '0px 16px 20px 16px',
    flex: 1,
  },
  getStartedButten: {
    maxWidth: 200,
    marginLeft: '18px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginTop: 16,
      maxWidth: '100%'
    },
    [theme.breakpoints.between(960, 1100)]: {
      maxWidth: 140,
    },
  },
  getStartedButten2: {
    // width: '220px !important',
    // position: 'absolute',
    // bottom: 20,
    // left: 17,
    // [theme.breakpoints.down('sm')]: {
    //   marginLeft: 0,
    //   marginTop: 16,
    //   width: '540px !important'
    // },
    // [theme.breakpoints.down('xs')]: {
    //   width: '220px !important',
    //   position: 'absolute',
    //   bottom: 20,
    //   left: 17,
    // },
  },
  menuButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: 1,
  },
  loginButton: {
    minWidth: 75,
    marginLeft: '18px',
    textDecoration: 'none',
    alignSelf: 'center',
    padding: '0px',
    color: '#293E57',
    fontSize: 18,
    fontFamily: 'Roboto',
    '&:hover': {
      textDecoration: 'none',
      color: '#EE4352',
      fontWeight: 'bold'
    },
    '&:visited': {
      textDecoration: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'flex-start',
      marginLeft: 0,
      padding: 0,
    },
  },
  loginButton2: {
    // minWidth: 75,
    textDecoration: 'none',
    // alignSelf: 'center',
    // padding: '0px',
    color: '#293E57',
    fontSize: 18,
    fontFamily: 'Roboto',
    // position: 'absolute',
    // bottom: 80,
    // left: 100,
    '&:hover': {
      textDecoration: 'none',
      color: colors.accentHover,
    },
    '&:visited': {
      textDecoration: 'none',
    },
    // [theme.breakpoints.between(500, 960)]: {
    //   textAlign: 'center',
    //   minWidth: '400px !important',
    //   left: '85px !important'
    // },
    // [theme.breakpoints.down(500)]: {
    //   minWidth: 75,
    //   textDecoration: 'none',
    //   alignSelf: 'center',
    //   padding: '0px',
    //   color: '#293E57',
    //   fontSize: 18,
    //   fontFamily: 'Roboto',
    //   position: 'absolute',
    //   bottom: 80,
    //   left: 100,
    // },
  },
  menuButton: {
    fontFamily: 'Roboto',
    fontSize: '18px',
    marginLeft: '18px',
    textDecoration: 'none',
    color: '#999',
    padding: 8,
    '&:hover': {
      textDecoration: 'none',
      color: '#999',
      // color: colors.primary,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      marginLeft: '15px',
    },
  },
  menuButton2: {
    fontFamily: 'Roboto',
    fontSize: '18px',
    textDecoration: 'none',
    color: colors.primary,
    paddingTop: 8,
    paddingBottom: 8,
  },
  menuButtonActive: {
    fontFamily: 'Roboto',
    fontSize: '18px',
    marginLeft: '18px',
    textDecoration: 'underline',
    color: colors.primary,
    padding: 8,
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'underline',
      color: colors.primary,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      marginLeft: '15px',
    },
  },
  drawerRoot: {
    width: '70%',
  },
  svg: {
    alignItems: 'center', 
    display: 'flex', 
    height: 30,
    [theme.breakpoints.down('md')]: {
      width: 100
    },
  },
  topMenu: {
    background: colors.primary,
    display: 'flex',
    justifyContent: 'flex-end', 
    paddingBlock: 10,
    paddingInline: 70, 
    gap: 24,
    [theme.breakpoints.down('sm')]: {
      paddingInline: 24, 
    },
    [theme.breakpoints.up(1920)]: {
      paddingInline: 200, 
    },
    [theme.breakpoints.between(960, 1100)]: {
      paddingInline: 35, 
    },
  },
  search: {
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', 
    gap: 10, 
    cursor: 'pointer'
  },
  link: {
    color: 'rgba(255, 255, 255, 0.50)', 
    textDecoration: 'none' 
  },
  img: {
    objectFit: 'contain', 
    height: 24, 
    width: 24, 
    cursor: 'pointer'
  },
  bottomNav: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 16,
    justifyContent: 'flex-end',
    height: '100%'
  },
  navHovered: {
    paddingInline: 16,
    paddingBlock: 12,
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.16)',
    backgroundColor: 'white',
    left: 30,
    borderRadius: 5,
    border: '1px solid #EBEBEB',
    position: 'absolute',
  },
}))

const gridStyles = makeStyles((theme) => ({
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    paddingInline: 16,
    paddingBlock: 12,
    boxShadow: '0px 20px 35px -10px rgba(48, 50, 101, 0.23)',
    backgroundColor: 'white',
    left: -250,
    top: 70,
    borderRadius: 5,
    border: '1px solid #EBEBEB',
    position: 'absolute',
    minWidth: 700,
    
  },
  gridItem: {
    display: 'flex',
    padding: 18,
    cursor: 'pointer',
    gap: 16,
    transition: '0.5s',

  },
  gridItemActive: {
    display: 'flex',
    padding: 18,
    background: '#FFEFF3',
    gap: 16,
    cursor: 'pointer',
    transition: '0.5s',
    borderRadius: 15
  },
  leftDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  imgHolder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    background: '#EFF2FF',
    height: 53,
    width: 53
   
  },
  imgHolderActive: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    background: '#FFEFF3',
    height: 53,
    width: 53
   
  },
  img: {
    width: 32,
    height: 32,
    objectFit: 'contain'
  },
  textHolder: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    alignItems: 'flex-start'
  }
}))

const Grid = ({ items, visible, show, setShow, close }) => {
  const classes = gridStyles()
  const history = useHistory()

  const handleShow = useCallback((id) => {
    setShow(id)
  }, [setShow])

  const menuRef = useRef()
  
  useEffect(() => {
    const closeResourcesMenu = (e) => {
      if (!menuRef.current?.contains(e.target)) {
        close()
      }
    }
    document.body.addEventListener('click', closeResourcesMenu)
    return () => document.body.removeEventListener('click', closeResourcesMenu)
  }, [close])

  const goToPage = ( url, newWindow) => {
    if (newWindow) {
      window.open(url, '_blank')
    } else {
      history.push(url)
    }
    close()
    // setShow(null)
  }


  
  return (
    <div style={{ visibility: visible ? 'visible' : 'hidden' }} ref={menuRef} className={classes.gridContainer}>
      {items.map((item, index) => {
        const isActive = index === show
        const { href, label, disabled, key, newWindow = false, scrollTo = null } = item
        const navigate = () => {
          // if user is in the same screen
          if (href === location?.pathname) {
            if (!scrollTo) {
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
              })
            } 
            else if (scrollTo === 'COSTS') {
              const yOffset = -90
              const y = document?.getElementById(scrollTo).getBoundingClientRect().top + window.scrollY + yOffset
              window.scrollTo({ top: y, behavior: 'smooth', block: 'center' })
            }
            else {
              document?.getElementById(scrollTo).scrollIntoView({ behavior: 'smooth', block: 'center' })
            }
          } else if (newWindow) {
            window.open(href, '_blank')
          } 
          else {
            history.push({
              pathname: href,
              scrollTo: scrollTo
            })
          }
        }
        return (

          <div key={key} className={isActive ? classes.gridItemActive : classes.gridItem} onClick={()=> goToPage(href, newWindow)} onMouseEnter={() => handleShow(index)}>
            <div className={classes.leftDiv}>
              <div className={isActive ? classes.imgHolderActive : classes.imgHolder}>
                <img src={item?.img} alt={item.label} className={classes.img} />
              </div>
            </div>
            <div className={classes.textHolder}>
              <Text weight='bold' size='footnote'>{item.title}</Text>
              <Text size='footnote'>{item.description}</Text>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default function NavBar() {
  const classes = useStyles()
  const currentScreen = useHistory().location?.pathname
  const [scrollDirection, setScrollDirection] = useState(null)
  const history = useHistory()
  const location = useLocation()
  const scrollDirectionNav = useScrollDirection(scrollDirection, setScrollDirection)
  const [dropDrownHovered, setDropDownHovered] = useState({
    resources: false
  })

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  })

  const { mobileView, drawerOpen } = state

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 960
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }))
    }
    setResponsiveness()
    window.addEventListener('resize', () => setResponsiveness())
  }, [])
  
  useEffect(() => {
    // Reset scroll direction on route change
    setScrollDirection(null)
  }, [location])

  const getStarted = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  
  }


  const [isHoveredItem, setIsHoveredItem] = useState(null)
  
  const handleHover = useCallback((id) => {
    setIsHoveredItem(id)
  }, [])

  const displayDesktop = () => {

    return (
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <div  className={classes.svg}>
            <img src='https://dyl2njn37ytxk.cloudfront.net/websiteSEO/svg/dataplayer%20logo.svg' alt='dataplayerLogo'/>
          </div>
        </RouterLink>
        <div
          className={classes.menuButtonContainer}
        >{GetMenuButtons()}
          {/* <a href="https://dashboard.dataplayer.io/" target="_blank" rel="noreferrer" className={classes.loginButton} >
            <CostumButton  title='Login' buttonType="link"  />
            Log in
          </a> */}
          <CostumButton
            
            title='Start now'
            buttonType="primaryGreen"
            className={classes.getStartedButten}
            onClick={() => getStarted('https://dashboard.dataplayer.io/signup')}
          />
        </div>
      </Toolbar>
    )
  }

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }))
    const handleDrawerClose = () => {
      setState((prevState) => ({ ...prevState, drawerOpen: false }))
      setDropDownHovered( (prev) => [{
        ...prev,
        resources: false
      }])
    }
      
    return (
      <Toolbar
        {...{
          className: classes.logo
        }}
      >
        <RouterLink to="/">
          <div className={classes.logoM}>
            <div style={{ alignItems: 'center', display: 'flex', height: 100 }}>
              <img src='https://dyl2njn37ytxk.cloudfront.net/websiteSEO/svg/dataplayer%20logo.svg' alt='dataplayerLogo' />
            </div>
          </div>
        </RouterLink>
        <IconButton
          {...{
            edge: 'end',
            color: 'inhert',
            'aria-label': 'menu',
            'aria-haspopup': 'true',
            onClick: handleDrawerOpen,
            // style: {
            //   paddingTop: 25,
            //   position: 'absolute',
            //   right: 0
            // },
          }}
        >
          <MenuIcon style={{
            width: 32,
            height: 32
          }} />
        </IconButton>
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={handleDrawerClose}
          classes={{ paper: classes.drawerRoot }}
        >
          <div className={classes.drawerContainer}>
            <div className={classes.logoM} style={{ maxWidth: '100%', justifyContent: 'space-between', height: 50 }}>
              <div style={{ alignItems: 'center', display: 'flex', height: 70 }}>
                <img src='https://dyl2njn37ytxk.cloudfront.net/websiteSEO/svg/dataplayer%20logo.svg' alt='dataplayerLogo' width={120} />
              </div>
              <IconButton
                disableFocusRipple
                centerRipple={true}
                onClick={handleDrawerClose}
                style={{ paddingTop: 16, paddingLeft: 82, height: 70 }}
              >
                <img src='https://dyl2njn37ytxk.cloudfront.net/websiteSEO/svg/close.svg' alt='close'  style={{ width: 16 }} />
              </IconButton>
            </div>
            <div style={{ height: 1, borderBottom: `1px solid ${colors.background}`, marginBottom: 10, marginTop: 20  }} />
            <div className={classes.mobileLinks}>
              {getDrawerChoices()}
              <div style={{
                width: '100%',
                height: 2,
                backgroundColor: '#f6f6f6',
                marginTop: 20,
                marginBottom: 20,
              }} />
              <div className={classes.bottomNav}>
                <a href="https://dashboard.dataplayer.io/" target="_blank" rel="noreferrer" className={classes.loginButton2} >
                Log in
                </a>
                <CostumButton
                  title='Get Started'
                  buttonType="primaryGreen"
                  className={classes.getStartedButten2}
                  onClick={() => getStarted('https://dashboard.dataplayer.io/signup')}
                />
              </div>
            </div>
          </div>
        </Drawer>
      </Toolbar>
    )
  }

  const getDrawerChoices = () => {

    const Item = ({ onClick, title, isLast, disabled }) => {
      return (
        <div key={title} style={{ minWidth: 120, cursor: disabled ? 'default' : 'pointer', }} onClick={disabled ? null : onClick}>
          <Text color={disabled ? '#dbdddf' : null} size={'body'}>{title}</Text>
          <hr style={{ marginTop: 8, marginBottom: 8 }} />
        </div>
      )
    }

    return headersData.map(({ key, label, href, disabled = false, newWindow = false, scrollTo = false, isDropDown, dropDownElements = [] }) => {
      const linkName = dropDrownHovered[key] ?  
        <img src='https://dyl2njn37ytxk.cloudfront.net/websiteSEO/svg/arrow.svg'alt='arrowUp' style={{ transform: 'rotateX(0.5turn)', fill: '#293E57 !important', width: 18 }} /> 
        : 
        <img src='https://dyl2njn37ytxk.cloudfront.net/websiteSEO/svg/arrow.svg' alt='arrowDown' style={{ fill: '#293E57 !important', width: 18 }} />
      if (isDropDown) {

        const handleClick = (key) => {
          const stateCopy = { ...dropDrownHovered }
          const nextValue = !stateCopy[key]
          Object.keys(stateCopy).forEach(key => stateCopy[key] = false)
          stateCopy[key] = nextValue
          setDropDownHovered(stateCopy)
        }
        
        return (

          <div key={label}>
            <div key={label} style={{ display: 'flex', position: 'relative', cursor: 'pointer', alignItems: 'center' }}>
              <div style={{ cursor: 'pointer', display: 'flex', color: href === currentScreen ? 'rgb(41, 62, 87)' : 'rgb(41, 62, 87)', fontWeight: href === currentScreen ? 'bold' : '400', 
                textDecoration: href === currentScreen ? 'underline' : 'none' }} onClick={() => {
                // setDropDownHovered( (prev) => [{
                //   ...prev,
                //   resources: false
                // }])
                // setState((prevState) => ({ ...prevState, drawerOpen: false }))
                // history.push(href)
                handleClick(key)
              }
              } className={classes.menuButton2}>{label}
              </div>
              <div className='arrow-fill' key={label} style={{ display: 'flex', position: 'absolute', right: 0 }} onClick={() => handleClick(key)}>{linkName}</div>
            </div>
            {dropDrownHovered[key] && <div
              style={{
                display: dropDrownHovered[key] ? 'block' : 'none',
                paddingBlock: 25,
                paddingInline: 9,
                backgroundColor: colors.white,
                left: 60,
                zIndex: 9999,
                color: 'white',
                top: 10,
                
              }}
            >
              {dropDownElements.map((item, index) => {
                const { href, label, disabled, key, newWindow = false, scrollTo } = item
                const navigate = () => {
                  setDropDownHovered( (prev) => [{
                    ...prev,
                    resources: false
                  }])
                  // if user is in the same screen
                  if (href === location?.pathname) {
                    if (!scrollTo) {
                      window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                      },
                      setState((prevState) => ({ ...prevState, drawerOpen: false })))
                    }
                    else if ( scrollTo === 'addOns' || scrollTo === 'COSTS') {
                      const yOffset = -70
                      const y = document?.getElementById(scrollTo).getBoundingClientRect().top + window.scrollY + yOffset
                      window.scrollTo({ top: y, behavior: 'smooth', block: 'start' })
                      setState((prevState) => ({ ...prevState, drawerOpen: false }))
                    }
                    else if (scrollTo === 'TESTIMONIALS') {
                      const yOffset = -260
                      const y = document?.getElementById(scrollTo).getBoundingClientRect().top + window.scrollY + yOffset
                      window.scrollTo({ top: y, behavior: 'smooth', block: 'start' })
                      setState((prevState) => ({ ...prevState, drawerOpen: false }))
                    }
                    else if (scrollTo === 'PLANS') {
                      const yOffset = -360
                      const y = document?.getElementById(scrollTo).getBoundingClientRect().top + window.scrollY + yOffset
                      window.scrollTo({ top: y, behavior: 'smooth', block: 'start' })
                      setState((prevState) => ({ ...prevState, drawerOpen: false }))
                    }
                    else {
                      document?.getElementById(scrollTo).scrollIntoView({ behavior: 'smooth', block: 'start' })
                      setState((prevState) => ({ ...prevState, drawerOpen: false }))
                    }
                  } else if (newWindow) {
                    window.open(href, '_blank')
                    setState((prevState) => ({ ...prevState, drawerOpen: false }))
                  } 
                  else {
                    history.push({
                      pathname: href,
                      scrollTo: scrollTo
                    })
                    setState((prevState) => ({ ...prevState, drawerOpen: false }))
                  }
                }
                
                return (
                  <Item
                    key={label}
                    onClick={navigate}
                    title={label}
                    isLast={index === dropDownElements.length - 1}
                    disabled={disabled}
                  />
                )
              })}
            </div>}
            
          </div>
        )
      }
      if (newWindow) {
        return (
          <MenuItem
            onClick={() => {
              if (newWindow) {
                setState((prevState) => ({ ...prevState, drawerOpen: false }))
                getStarted(href)
              }
            }}
            key={label + key}
            disabled={disabled}
            style={{ paddingLeft: 0, color: href === currentScreen ? 'rgb(41, 62, 87)' : 'rgb(41, 62, 87)' }}>{label}</MenuItem>
        )
      }
      return (
        <Link
          disab
          key={label + key}
          onClick={() => {
            if (href === location?.pathname && !scrollTo) {
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
              })
            }
            if (newWindow) {
              setState((prevState) => ({ ...prevState, drawerOpen: false }))
              getStarted(href)
            }
            if (!disabled) {
              setState((prevState) => ({ ...prevState, drawerOpen: false }))
            }
          }}
          component={RouterLink}
          to={disabled ? '#' : {
            pathname: href,
            scrollTo: scrollTo,
          }}
          style={{ textDecoration: 'none', color: colors.primary }}
          activeClassName={classes.menuButtonActive}
          exact={true}
        >
          <MenuItem disabled={disabled} style={{ paddingLeft: 0, color: href === currentScreen ? colors.primary : null, fontWeight: href === currentScreen ? 'bold' : '400', fontSize: '18px', 
            textDecoration: href === currentScreen ? 'underline' : 'none' }}>{label}</MenuItem>
        </Link>
      )
    })
  }

  const GetMenuButtons = () => {

    return desktopHeaderData.map(({
      key,
      label,
      href,
      isDropDown = false,
      newWindow = false,
      isDisabled = false,
      dropDownElements = [],
      scrollTo = false
    }) => {
      if (isDropDown) {
        const updateState = (key, state) => {
          setDropDownHovered(prev => ({
            ...prev,
            [key]: state
          }))
        }

        const Item = ({ onClick, title, isLast, disabled }) => {
          return (
            <div key={title} style={{ minWidth: 190, cursor: disabled ? 'default' : 'pointer' }} onClick={disabled ? null : onClick}>
              <Text color={disabled ? '#dbdddf' : '#999'} size={'footnote'}>{title}</Text>
              {!isLast && <hr style={{ marginTop: 8, marginBottom: 8 }} />}
            </div>
          )
        }

        return (

          <div style={{ position: 'relative' }} key={label} onMouseEnter={label === 'Resources' ? null : () => { updateState(key, true) }} 
            onMouseLeave={label === 'Resources' ? null :() => { updateState(key, false) }}>
            <div onClick={() => {
              if (dropDrownHovered.resources === true) {
                setDropDownHovered( (prev) => [{
                  ...prev,
                  resources: false
                }])
              }
              updateState(key, !dropDrownHovered[key])
              history.push(href)
            }} style={{ cursor: 'pointer', color: href === currentScreen ? 'rgb(41, 62, 87)' : '#999', fontWeight: href === currentScreen ? 'bold' : '400', 
              textDecoration: href === currentScreen ? 'underline' : 'none' }} className={classes.menuButton}>{label}
              {isDropDown && <img src='https://dyl2njn37ytxk.cloudfront.net/websiteSEO/svg/arrow.svg' alt='arrow' style={{ width: 8, height: 8, objectFit: 'contain', marginInlineStart: 8 }} />}
            </div>
            {dropDrownHovered.resources === true ? 
              <Grid
                visible={dropDrownHovered[key]}
                key={label}
                items={dropDownElements}
                show={isHoveredItem}
                setShow={setIsHoveredItem}
                close={() => setDropDownHovered( (prev) => [{
                  ...prev,
                  resources: false
                }])
                
                }
              />
              :
              <div
                style={{
                  position: 'absolute',
                  visibility: dropDrownHovered[key] ? 'visible' : 'hidden',
                  padding: 16,
                  boxShadow: '#efefef 0px 0px 2px 1px',
                  backgroundColor: 'white',
                  left: 30,
                  borderRadius: 6
                }}
              >
                {dropDownElements.map((item, index) => {
                  const { href, label, disabled, key, newWindow = false, scrollTo = null } = item
                  const navigate = () => {
                  // if user is in the same screen
                    if (href === location?.pathname) {
                      if (!scrollTo) {
                        window.scrollTo({
                          top: 0,
                          left: 0,
                          behavior: 'smooth'
                        })
                      } 
                      else if (scrollTo === 'COSTS') {
                        const yOffset = -90
                        const y = document?.getElementById(scrollTo).getBoundingClientRect().top + window.scrollY + yOffset
                        window.scrollTo({ top: y, behavior: 'smooth', block: 'center' })
                      }
                      else {
                        document?.getElementById(scrollTo).scrollIntoView({ behavior: 'smooth', block: 'center' })
                      }
                    } else if (newWindow) {
                      window.open(href, '_blank')
                    } 
                    else {
                      history.push({
                        pathname: href,
                        scrollTo: scrollTo
                      })
                    }
                  }
                  return (
                    <Item
                      key={key}
                      onClick={navigate}
                      title={label}
                      isLast={index === dropDownElements.length - 1}
                      disabled={disabled}
                    />
                  )
                })}
              </div>
            }
             
                
              
            
          </div>
        )
      }

      // New item since navlink considers pressing the item navigation to a screen and the button gets the current screen color
      if (newWindow) {
        return (
          <div
            key={label}
            className={classes.menuButton}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (newWindow) {
                window.open(href, '_blank')
              }
            }}
          >
            {label}
          </div>
        )
      }

      return (
        <>
          <NavLink
            key={label}
            className={classes.menuButton}
            to={href}
            activeClassName={classes.menuButtonActive}
            exact={true}
            onClick={() => {
              if (href === location?.pathname && !scrollTo) {
                if (dropDrownHovered.resources === true) {
                  setDropDownHovered( (prev) => [{
                    ...prev,
                    resources: false
                  }])
                }
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: 'smooth'
                })
                return
              }
              if (newWindow) {
                if (dropDrownHovered.resources === true) {
                  setDropDownHovered( (prev) => [{
                    ...prev,
                    about: false,
                    pricing: false,
                    resources: false
                  }])
                }
                window.open(href, '_blank')
              }
              if (dropDrownHovered.resources === true) {
                setDropDownHovered( (prev) => [{
                  ...prev,
                  resources: false
                }])
              }
              
            }}
          >
            {label}
          </NavLink>
        </>
      )
    })
  }


  return (
    <AppBar className={scrollDirectionNav === 'down' ? classes.hidden : classes.header}>
      <div className={classes.topMenu}>
        <RouterLink to='/demo' className={classes.link}>
          <Text size='footnote' color='rgba(255, 255, 255, 0.50)'>Get Demo</Text>
        </RouterLink>
        <a  href='https://dashboard.dataplayer.io/login' target='_blank' rel='noreferrer' className={classes.link}>
          <Text size='footnote' color='rgba(255, 255, 255, 0.50)'>Log In</Text>
        </a>
        <a href="https://www.facebook.com/dataplayerio/" target='_blank' rel='noreferrer'>
          <img src='https://dyl2njn37ytxk.cloudfront.net/websiteSEO/svg/facebook.svg' className={classes.img} />
        </a>
        <a href="https://www.instagram.com/dataplayer.io/" target='_blank' rel='noreferrer'>
          <img src="https://dyl2njn37ytxk.cloudfront.net/website-sep9/navbar/insta%20(1).svg" className={classes.img} />
        </a>
        <a href="https://www.linkedin.com/company/dataplayerio/" target='_blank' rel='noreferrer'>
          <img src="https://dyl2njn37ytxk.cloudfront.net/websiteSEO/svg/linkedin.svg" className={classes.img} />
        </a>
      </div>
      {mobileView ? displayMobile() : displayDesktop()}
    </AppBar>
  )
}