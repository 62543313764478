import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { propTypes } from 'react-bootstrap/esm/Image'
import { colors } from '../../Themes'
import { loadingDataplayer } from '../../Themes/Images'
import Loading from 'react-lottie'
let classNames = require('classnames')

const LOADING_DATAPLAYER = {
  loop: true,
  autoplay: true,
  animationData: loadingDataplayer,
  rendererSettings: {
    className: 'xMidYMid slice',
  },
}

function Button(props) {
  let { style, isLoading, disabled, ...rest } = props
  const useStyles = makeStyles((theme) => ({
    ButtonRoot: {
      paddingTop: '12px',
      paddingBottom: '12px',
      borderRadius: '5px',
      fontSize: '20px',
      color: 'rgba(255, 255, 255, 100)',
      outline: 'none',
      cursor: 'default',
      width: '100%',
      ...style,
      [theme.breakpoints.down('md')]: {
        paddingTop: '12px',
        paddingBottom: '12px',
      },
      '&$focused': {
        outline: 'none !important'
      },
    },
    primary: {
      borderRadius: 5,
      backgroundColor: 'rgba(152, 35, 92, 1)',
      color: 'white',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: 'rgba(255, 255, 255, 0)',
      transition: '0.5s',
      '&:hover': {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'rgba(152, 35, 92, 1)',
        border: '1px solid rgba(152, 35, 92, 1)',
        backgroundColor: 'rgba(255, 255, 255, 0)',
        color: 'rgba(152, 35, 92, 1)',
      },
    },
    primaryRed: {
      borderRadius: 5,
      backgroundColor: '#EE4352',
      color: 'white',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: 'rgba(255, 255, 255, 0)',
      transition: '0.5s',
      '&:hover': {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'rgba(152, 35, 92, 1)',
        border: '1px solid rgba(152, 35, 92, 1)',
        backgroundColor: 'rgba(255, 255, 255, 0)',
        color: 'rgba(152, 35, 92, 1)',
      },
    },
    primaryWhite: {
      borderRadius: 5,
      backgroundColor: colors.white,
      color: colors.primary,
      border: `1px solid ${colors.primary}`,
      transition: '0.5s',
      fontSize: '16px !important',
      // '&:hover': {
      //   background: colors.primary,
      //   color: 'rgba(255, 255, 255, 1)',
      // },
    },
    primaryWhiteHome: {
      borderRadius: 5,
      backgroundColor: '#F8F8F8',
      color: colors.primary,
      border: `1px solid ${colors.primary}`,
      transition: '0.5s',
      fontSize: '16px !important',
      // '&:hover': {
      //   background: colors.primary,
      //   color: 'rgba(255, 255, 255, 1)',
      // },
    },
    primaryAccent: {
      borderRadius: '5px',
      backgroundColor: '#90205D',
      color: 'white',
      fontSize: '18px',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: 'rgba(255, 255, 255, 0)',
      transition: '0.5s',
      '&:hover': {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'rgba(152, 35, 92, 1)',
        border: '1px solid rgba(152, 35, 92, 1)',
        backgroundColor: 'rgba(255, 255, 255, 0)',
        color: 'rgba(152, 35, 92, 1)',
      },
    },
    primaryGreen: {
      borderRadius: '5px',
      backgroundColor: '#293E57',
      color: '#FFFFFF',
      fontSize: '16px !important',
      // width: '270px',
      border: '1px solid #293E57',
      transition: '0.5s',
      '&:hover': {
        borderStyle: 'solid',
        border: '1px solid #293E57',
        backgroundColor: '#FFFFFF',
        color: '#293E57',
      },
    },
    primaryGreen2: {
      borderRadius: 5,
      backgroundColor: '#293E57',
      color: '#FFFFFF',
      fontSize: '18px !important',
      width: '270px',
      border: '1px solid #293E57',
      transition: '0.5s',
      '&:hover': {
        borderStyle: 'solid',
        border: '1px solid #293E57',
        backgroundColor: '#FFFFFF',
        color: '#293E57',
      },
    },
    primaryMaybe: {
      borderRadius: 5,
      backgroundColor: '#293E57',
      color: '#FFFFFF',
      fontSize: '18px !important',
      width: '270px',
      border: '1px solid #293E57',
      transition: '0.5s',
      '&:hover': {
        borderStyle: 'solid',
        border: '1px solid #182A3F',
        backgroundColor: '#182A3F',
        color: '#ffffff',
      },
    },
    primaryBlue: {
      borderRadius: 5,
      backgroundColor: 'rgba(49, 65, 85, 1)',
      color: 'white',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: 'rgba(255, 255, 255, 0)',
      transition: '0.5s',
      '&:hover': {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'rgba(49, 65, 85, 1)',
        border: '1px solid rgba(49, 65, 85, 1)',
        backgroundColor: 'rgba(255, 255, 255, 0)',
        color: 'rgba(49, 65, 85, 1)',
      },
    },
    primaryRose: {
      borderRadius: 5,
      backgroundColor: 'rgba(236, 66, 82, 1)',
      color: 'white',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: 'rgba(255, 255, 255, 0)',
      transition: '0.5s',
      '&:hover': {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'rgba(236, 66, 82, 1)',
        border: '1px solid rgba(236, 66, 82, 1)',
        backgroundColor: 'rgba(255, 255, 255, 0)',
        color: 'rgba(236, 66, 82, 1)',
      },
    },
    secondary: {
      borderRadius: 5,
      border: '1px solid rgba(152, 35, 92, 1)',
      backgroundColor: 'rgba(255, 255, 255, 0)',
      color: 'rgba(152, 35, 92, 1)',
      transition: '0.5s',
      '&:hover': {
        backgroundColor: 'rgba(152, 35, 92, 1)',
        color: 'rgba(255, 255, 255, 1)',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'rgba(255, 255, 255, 0)',
      },
    },
    secondaryBlue: {
      borderRadius: 5,
      border: '1px solid rgba(49, 65, 85, 1)',
      backgroundColor: 'rgba(255, 255, 255, 0)',
      color: 'rgba(49, 65, 85, 1)',
      transition: '0.5s',
      '&:hover': {
        backgroundColor: 'rgba(49, 65, 85, 1)',
        color: 'rgba(255, 255, 255, 1)',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'rgba(255, 255, 255, 0)',
      },
    },
    secondaryRed: {
      borderRadius: 5,
      border: '1px solid #293E57',
      backgroundColor: 'rgba(255, 255, 255, 0)',
      color: 'rgba(49, 65, 85, 1)',
      transition: '0.5s',
      '&:hover': {
        backgroundColor: '#EE4352',
        color: 'rgba(255, 255, 255, 1)',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#EE4352',
      },
    },
    link: {
      border: '0px',
      backgroundColor: 'transparent',
      padding: 0,
      transition: '0.5s',
      color: 'rgba(49, 65, 85, 1)',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    secondLink: {
      border: '0px',
      backgroundColor: 'transparent',
      padding: 0,
      transition: '0.5s',
      color: 'rgba(236, 66, 82, 1)',
      fontSize: 26,
      fontWeight: 500,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    disabled: {
      borderRadius: 5,
      border: '1px solid rgba(204, 204, 204, 100)',
      backgroundColor: 'rgba(204, 204, 204, 100)',
      color: 'rgba(255, 255, 255, 100)',
      fontSize: '16px',
    },
    disabled2: {
      borderColor: '#293E57',
      borderRadius: '5px',
      backgroundColor: '#FFFFFF',
      color: '#293E57',
      borderWidth: 1,
      fontSize: '18px',
      transition: '0.5s',
      '&:hover': {
        backgroundColor: '#293E57',
        color: 'rgba(255, 255, 255, 1)',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#293E57',
      },
    }
  }))

  const classes = useStyles()

  return (
  // ... props (if someone wants to add inline style to it)
    <button  {...rest} className={classNames(classes.ButtonRoot, classes[props.buttonType], props.className)} disabled={disabled} > 
      {props.isLoading ? <div>
        <Loading options={LOADING_DATAPLAYER} height={24} />
      </div> : props.title}
      {props.children}
    </button>
  )
}

export default Button

Button.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string,
  disabled: propTypes.boolean
}